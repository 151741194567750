<template>
  <div class="bg-white md:pt-[12vw] pt-[25vw] md:pb-[10vw] pb-[16vw] font-work">
    <Container v-if="credits">
      <CreditsBlueHeading>Credits</CreditsBlueHeading>

      <div class="pt-[3vw] grid grid-cols-1 md:grid-cols-12 gap-y-[16vw] md:gap-y-[7vw]">
        <div class="category md:col-span-8 md:col-start-3">
          <CreditsAccordion :credits="credits"
                            class="mt-[6vw] md:mt-[2.5vw]"
          />
        </div>
      </div>
    </Container>
  </div>
</template>

<script setup lang="ts">
import Credits from '~/assets/credits/Licences_Export_JSON.json';

const credits = Credits;
</script>
