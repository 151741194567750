<template>
  <Disclosure ref="disclosure"
              :default-open="defaultOpen"
              as="div"
              v-slot="{ open }"
              class="scroll-mt-[18vw] md:scroll-mt-[6vw] flex bg-indigo/10 rounded-[2.5vw] md:rounded-[1.25vw] flex-col"
  >
    <DisclosureButton class="p-[5vw] md:p-[1.75vw] space-x-[4vw] md:space-x-[2vw] focus:outline-none text-[4.25vw] md:text-[1.25vw] items-center md:leading-[1.875vw] leading-[6vw] text-left font-semibold flex justify-between">
      <span class="flex-grow">{{ name }}</span>
      <IconArrowDownSFill class="w-[6vw] h-[6vw] md:w-[2.5vw] md:h-[2.5vw] transform duration-150 flex-shrink-0"
                          :class="open ? '-scale-y-100' : 'scale-y-100'"
      />
    </DisclosureButton>
    <TransitionExpand>
      <DisclosurePanel class="md:max-w-[47.5vw]">
        <a v-if="homepage"
           :href="homepage"
           target="_blank"
           class="block text-[4vw] md:text-[1.125vw] leading-[6vw] md:leading-[1.7vw] hover:underline px-[5vw] md:px-[1.75vw] pb-[2.5vw] md:pb-[1vw]"
        >HOMEPAGE ></a>
        <div class="px-[5vw] pb-[5vw] md:px-[1.75vw] md:pb-[1.75vw] disclosure prose"
             v-html="licenceText"
        />
      </DisclosurePanel>
    </TransitionExpand>
  </Disclosure>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ref } from 'vue';

type AccordionDisclosureProps = {
  licenceText: string;
  name: string;
  homepage?: string;
};

defineProps<AccordionDisclosureProps>();
const disclosure = ref(null);
const defaultOpen = ref(false);
</script>

<style>
.disclosure a {
  @apply underline;
}
</style>
